import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLeadPhoneNumber,
  selectLeadToken,
  selectLeadEmail,
  selectOptin,
} from '../selectors';
import { createBundle, setSmsOptin } from '../actions';

const BundlePicker = ({ currentStep, partner_name, ticket_dispatch_id }) => {
  const optin = useSelector(selectOptin);
  const token = useSelector(selectLeadToken);
  const phoneNumber = useSelector(selectLeadPhoneNumber);
  const email = useSelector(selectLeadEmail);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [noThanks, setNoThanks] = useState(false);
  useEffect(() => {
    if (token && currentStep.field === 'bundle' && loading && optin) {
      answer(optin);
    }
  }, [token, currentStep, optin]);

  const answer = (ans) => {
    if (token) {
      dispatch(setSmsOptin(ans));
      new Promise((resolve, reject) => {
        dispatch(
          createBundle(
            token,
            ticket_dispatch_id,
            partner_name,
            ans,
            resolve,
            reject
          )
        );
      }).then(() => {
        setLoading(false);
        setTimeout(() => {
          window.location.href = `/offers/${token}`;
        }, 30000);
      });
    }
  };

  return (
    <div className='grid-x grid-padding-x grid-padding-y perks'>
      <div
        className='small-12 cell float-center ds-container'
        style={{ textAlign: 'center' }}
      >
        {optin && !noThanks && (
          <>
            <p>You're in!</p>
            <p>
              Your first Ticket Drop is on it's way to {phoneNumber}. Check your
              messages to confirm your number and select your tickets. It may
              take a minute or two for it to arrive.
            </p>
          </>
        )}
        {noThanks && (
          <>
            <p>You're in!</p>
            <p>
              Your first Ticket Drop is on it’s way to <strong>{email}</strong>.
              Check your messages to and follow the link to select your tickets.
            </p>
          </>
        )}
        {!optin && !noThanks && (
          <>
            <p>You're In!</p>
            <p>
              We send tickets via text so they don't get lost in your email
              inbox.
            </p>
            <p>
              <button
                style={{ margin: 'auto', maxWidth: '300px' }}
                type='button'
                onClick={() => answer(true)}
                disabled={!token}
              >
                SEND ME TEXTS
              </button>
            </p>
            <p>
              <button
                style={{ margin: 'auto', maxWidth: '300px' }}
                type='button'
                disabled={!token}
                onClick={() => {
                  setNoThanks(true);
                  answer(false);
                }}
              >
                SEND MY TICKETS VIA EMAIL
              </button>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default BundlePicker;
